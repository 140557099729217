<template>
  <span>
    <b-card-actions v-if="$permissionAbility(SITE_LIST_SHOW, permissions)" title="Filters" action-collapse>
    <div>
      <!-- search input -->
      <div>
        <b-row>
          <b-col
              md="3"
              lg="3"
              xs="12"
              class="mb-2 mb-md-0"
          >
           <b-form-input
               v-on:keyup="onSearch"
               v-model.lazy="searchTerm"
               placeholder="Search Website"
               type="text"
               class="d-inline-block mr-sm-1"
           />
          </b-col>
          <b-col md="3" lg="3" xs="12" class="mb-2 mb-md-0">
              <v-select
                  id="niche"
                  v-model="filterSelectNicheId"
                  :options="filteredNicheOptions"
                  :reduce="(option) => option.id"
                  label="name"
                  placeholder="Select Niche"
                  @input="loadItems"
              />
          </b-col>
          <b-col md="3" lg="3" xs="12" class="mb-2 mb-md-0">
             <v-select
                 id="seller"
                 v-model="filterSelectSellersId"
                 :options="filteredSellerOptions"
                 :reduce="(option) => option.id"
                 label="name"
                 placeholder="Select Seller"
                 @input="loadItems"
             />
          </b-col>
          <b-col
              md="3"
              lg="3"
              xs="12"
              class="mb-2 mb-md-0"
          >
            <v-select
                v-model="filterSelectStatusId"
                :options="outreachStatusConstants"
                :reduce="(item) => item.value"
                label="name"
                placeholder="Select Status"
                class="mb-1 custom-font"
                @input="loadItems"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card-actions>
    <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
            class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template v-if="$permissionAbility(SITE_LIST_CREATE, permissions)">
            <b-button
                class="flex-shrink-0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-on:click="showModal"
            >
              Create
            </b-button>
          </template>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
          v-if="$permissionAbility(SITE_LIST_SHOW, permissions)"
          styleClass="vgt-table table-custom-style striped"
          :line-numbers="false"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :rows="rows"
          :columns="columns"
          :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
          :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Website -->
          <template v-if="props?.column?.field === 'website_format'">
            <div v-if="props?.row?.website">
              <a
                  :href="props.row.website"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="font-weight-bold"
              >
                {{ formatWebsite(props?.row?.website) }}
              </a>
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- Niche -->
          <template v-if="props?.column?.field === 'niche_format'">
            <div v-if="props?.row?.niche">
              <span class="font-weight-bold">{{
                  props?.row?.niche?.data?.name
                }}</span>
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- Seller -->
          <template v-if="props?.column?.field === 'seller_format'">
            <div v-if="props?.row?.seller?.data?.name">
              <span class="font-weight-bold">{{
                  props?.row?.seller?.data?.name
                }}</span>
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- Traffic -->
          <template v-if="props?.column?.field === 'traffic_format'">
            <div v-if="props?.row?.traffic">
              <b-badge variant="light-primary">{{ formatNumber(props?.row?.traffic) }}</b-badge>
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>

          <!-- Traffic Value Format -->
          <template v-if="props?.column?.field === 'traffic_value_format'">
            <div v-if="props?.row?.traffic_value">
              <b-badge variant="light-success">USD {{ formatNumber(props?.row?.traffic_value) }}</b-badge>
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>
          <!-- Traffic Value Format -->
          <template v-if="props?.column?.field === 'spam_score_format'">
            <div v-if="props?.row?.spam_score">

              <b-badge variant="light-danger">{{ props?.row?.spam_score }} %</b-badge>
            </div>
            <div v-else>
              <b-badge variant="light-warning">NA</b-badge>
            </div>
          </template>
          <!-- Status -->
          <template v-if="props?.column?.field === 'status_format'">
             <b-badge :variant="getStatusVariant(props?.row?.status)">
                {{ props?.row?.status_text }}
              </b-badge>
          </template>

          <span v-if="props.column.field === 'action'">

            <span v-if="$permissionAbility(SITE_LIST_SHOW, permissions)" @click="onApprove(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="EyeIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="View"
                    size="16"
                />
              </span>

            <template v-if="$permissionAbility(SITE_LIST_EDIT, permissions)"
            >
              <span @click="onShow(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    size="16"
                />
              </span>
            </template>

            <template v-if="$permissionAbility(SITE_LIST_DELETE, permissions)">
              <span @click="onDelete(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Delete"
                    size="16"
                />
              </span>
            </template>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['10', '25', '50', '100', '500']"
                  class="mx-1"
                  @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
        id="modal-create-form"
        centered
        :title="modelType === 'editModel' ? 'Edit Site' : 'Create Site'"
        hide-footer
        @hidden="hiddenModal"
        size="lg"
        no-close-on-backdrop
    >
      <validation-observer ref="createFormValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- website -->
          <b-form-group
              label="Website"
              label-for="website"
              class="required-label"
          >
            <validation-provider
                #default="{ errors }"
                name="website"
                vid="website"
                rules="required"
            >
              <b-form-input
                  id="website"
                  type="text"
                  v-model="websiteUrl"
                  :state="errors.length > 0 ? false : null"
                  placeholder="The website must be a valid URL. (e.g https://www.example.com)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-row>

            <b-col md="4" lg="6" xs="12">
              <!-- Niche -->
              <b-form-group
                  label="Niche"
                  label-for="niche"
                  class="required-label"
              >
                <validation-provider
                    #default="{ errors }"
                    name="niche"
                    vid="niche"
                    rules="required"
                >
                  <v-select
                      id="niche"
                      v-model="selectNicheId"
                      :options="filteredNicheOptions"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="Choose Here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="6" xs="12">
              <!-- Seller -->
              <b-form-group
                  label="Seller"
                  label-for="seller"
                  class="required-label"
              >
                <validation-provider
                    #default="{ errors }"
                    name="seller"
                    vid="seller"
                    rules="required"
                >
                  <v-select
                      id="seller"
                      v-model="selectSellerId"
                      :options="filteredSellerOptions"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="Choose Here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

             <b-col md="4" lg="6" xs="12">
              <!-- referal_domain -->
              <b-form-group label="Referral Domain" label-for="referal_domain">
                <validation-provider
                    #default="{ errors }"
                    name="referal_domain"
                    vid="referal_domain"
                >
                  <b-form-input
                      id="referal_domain"
                      type="number"
                      v-model="referalDomainInput"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Referral Domain"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

             <b-col md="4" lg="6" xs="12">
              <!-- domain_authority -->
              <b-form-group label="Domain Authority" label-for="domain_authority">
                <validation-provider
                    #default="{ errors }"
                    name="domain_authority"
                    vid="domain_authority"
                >
                  <b-form-input
                      id="domain_authority"
                      type="number"
                      v-model="domainAuthorityInput"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Domain Authority"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

             <b-col md="4" lg="4" xs="12">
              <!-- Traffic -->
              <b-form-group label="Traffic" label-for="traffic">
                <validation-provider
                    #default="{ errors }"
                    name="traffic"
                    vid="traffic"
                >
                  <b-form-input
                      id="traffic"
                      type="number"
                      v-model="trafficInput"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Traffic"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <!-- Traffic Value -->
              <b-form-group label="Traffic Value" label-for="traffic_value">
                <validation-provider
                    #default="{ errors }"
                    name="traffic_value"
                    vid="traffic_value"
                >
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="spamScoreAddon">USD</span>
                      </div>
                      <b-form-input
                          id="traffic_value"
                          type="number"
                          v-model="trafficValueInput"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Traffic Value"
                      />
                    </div>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

           <b-col md="4" lg="4" xs="12">
            <!-- spam_score -->
            <b-form-group label="Spam Score" label-for="spam_score">
              <validation-provider
                  #default="{ errors }"
                  name="spam score"
                  vid="spam_score"
                  rules="required|numeric|min_value:0|max_value:100"
              >
                <div class="input-group">
                  <b-form-input
                      id="spam_score"
                      type="number"
                      v-model="spamScoreInput"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Spam Score"
                      aria-describedby="spamScoreAddon"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text" id="spamScoreAddon">%</span>
                  </div>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

             <b-col md="12" lg="12" xs="12">
              <!-- remarks -->
              <b-form-group label="Remarks" label-for="remarks">
                <validation-provider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                >
                  <b-form-textarea
                      id="remarks"
                      v-model="remarksInput"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Remarks"
                      rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

<!--            caption image form repeater-->
             <b-col md="12" lg="12" xs="12">
               <div class="mt-1">
                 <b>Image</b>
                 <h6 v-if="captionImages.length === 0" class="text-danger text-center">No Image Selected</h6>
                  <div v-else v-for="(form, index) in captionImages" :key="index" class="mt-1">
                  <b-row>
                    <b-col md="5" lg="5" xs="12">
                      <b-form-group class="required-label" :label="'Caption'" :label-for="'caption-' + index">
                        <validation-provider
                            #default="{ errors }"
                            :name="'caption'"
                            :vid="'caption-image-' + index"
                            rules="required"
                        >
                          <b-form-input
                              :id="'caption-' + index"
                              type="text"
                              v-model="form.caption"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Caption"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="5" lg="5" xs="12">
                      <b-form-group
                          :label="'Image'"
                          :label-for="'file-' + index"
                          :class="!form.required ? '' : 'required-label'"
                      >
                        <validation-provider
                            #default="{ errors }"
                            :name="'image'"
                            :rules="!form.required ? 'size:5048|ext:jpg,jpeg,png' : 'required|size:5048|ext:jpg,jpeg,png'"
                            :vid="'image-' + index"
                        >
                          <b-form-file
                              :id="'file-' + index"
                              v-model="form.file"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                              @change="handleCaptionImageChange(index)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-link v-if="form.url" :href="form.url" target="_blank" class="d-flex align-items-center">
                          <feather-icon icon="DownloadIcon" class="mr-2" />
                          View
                        </b-link>
                      </b-form-group>
                    </b-col>
                    <b-col md="2" lg="2" xs="12">
                        <b-button variant="danger" class="mt-2" @click="removeCaptionImage(index)">X</b-button>
                    </b-col>
                  </b-row>
                </div>

                 <b-row>
                   <b-col cols="10"></b-col>
                    <b-col cols="2">
                      <b-button :disabled="captionImages.length >= 4" variant="primary" @click="addCaptionImage">Add</b-button>
                    </b-col>
                 </b-row>

               </div>
             </b-col>
<!--            end caption image form repeater-->

            <b-col cols="12">
              <b>Href</b>
            </b-col>

<!--            start href form repeater-->
            <b-col md="12" lg="12" xs="12">
               <div>
                   <h6 v-if="captionHrefs.length === 0" class="text-danger text-center">No Href Selected</h6>
                  <div v-else v-for="(form, index) in captionHrefs" :key="index" class="mt-1">
                  <b-row>
                    <b-col md="5" lg="5" xs="12">
                      <b-form-group class="required-label" :label="'Caption'" :label-for="'caption-' + index">
                        <validation-provider
                            #default="{ errors }"
                            :name="'caption'"
                            :vid="'caption-href-' + index"
                            rules="required"
                        >
                          <b-form-input
                              :id="'caption-' + index"
                              type="text"
                              v-model="form.caption"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Caption"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="5" lg="5" xs="12">
                      <b-form-group class="required-label" :label="'Href'" :label-for="'href-' + index">
                        <validation-provider
                            #default="{ errors }"
                            :name="'href'"
                            :vid="'href-' + index"
                            rules="required|url"
                        >
                          <b-form-input
                              :id="'href-' + index"
                              type="text"
                              v-model="form.href"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Href"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="2" lg="2" xs="12">
                        <b-button variant="danger" class="mt-2" @click="removeCaptionHref(index)">X</b-button>
                    </b-col>
                  </b-row>
                </div>

                 <b-row>
                   <b-col cols="10"></b-col>
                    <b-col cols="2">
                      <b-button :disabled="captionHrefs.length >= 4" variant="primary" @click="addCaptionHref">Add</b-button>
                    </b-col>
                 </b-row>

               </div>
             </b-col>
<!--            end href form repeater-->
            <b-col md="6" lg="6" xs="12">
              <!-- approved_for -->
              <b-form-group label="Approved For" label-for="approved_for" class="required-label">
                <validation-provider
                    #default="{ errors }"
                    name="approved for"
                    vid="approved_for"
                    class="required-label"
                    rules="required"
                >
                  <v-select
                      id="approved_for"
                      v-model="approvedForId"
                      @input="renderNichePricing"
                      :options="filteredApprovedNicheOptions"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="Choose Here"
                      rules="required"
                      multiple
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="6" xs="12">
              <!-- Traffic Value Currency-->
              <b-form-group label="Currency" label-for="traffic_value_currency">
                <validation-provider
                    #default="{ errors }"
                    name="currency"
                    vid="traffic_value_currency"
                    rules="required"
                >
                 <v-select
                     id="project"
                     v-model="selectedCurrency"
                     :options="filteredCurrencyOptions"
                     :reduce="(option) => option.id"
                     label="name"
                     placeholder="Choose Here"
                 />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>

          <b-card v-if="selectedApprovedNichePricing.length > 0">
            <h3 class="my-1">Approved for Niche Pricing </h3>
            <b-card-body>
              <div v-for="(nicheApprove, index) in selectedApprovedNichePricing">
                <h5 class="my-1">{{ nicheApprove.name }}</h5>
                <b-row>
                  <b-col md="6" lg="6" xs="12">
                    <!-- Traffic -->
                    <b-form-group label="Guest Post" label-for="GuestPost">
                      <validation-provider
                          #default="{ errors }"
                          name="Guest Post"
                          vid="guestPost"
                      >
                        <b-form-input
                            id="guestPost"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            :value="nicheApprove.guest_post_price"
                            @input="(value) => nicheApprove.guest_post_price = Number(value)"
                            placeholder="Guest Post"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6" lg="6" xs="12">
                    <b-form-group label="Link Insertion" label-for="linkInsertion">
                      <validation-provider
                          #default="{ errors }"
                          name="Link Insertion"
                          vid="linkInsertion"
                      >
                        <b-form-input
                            id="linkInsertion"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Link Insertion"
                            :value="nicheApprove.link_insertion_price"
                            @input="(value) => nicheApprove.link_insertion_price = Number(value)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-card>

          <!-- loading button -->
          <template v-if="isSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>
          <!-- submit button -->
          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
        id="approve-modal"
        centered
        :title="'Website Information'"
        hide-footer
        @hidden="hiddenApproveModal"
        size="xl"
        no-close-on-backdrop
    >
      <div class="px-1 py-1">
        <h3>General <b-badge  :variant="getStatusVariant(approveData?.status)">{{ approveData?.status_text }}</b-badge></h3>
        <b-badge variant="info">{{  this.approveData?.website }}</b-badge>

        <b-row class="mt-2">
          <b-col class="mb-1" sm="6" lg="2">
            <b>Domain Authority</b>

            <div >
              <template v-if="enableWidget">
                <vue-apex-charts
                    type="donut"
                    height="350"
                    :options="donutChart.chartOptions"
                    :series="donutChart.series"
                />
              </template>
              </div>

          </b-col>
          <b-col class="mb-1" sm="6" lg="2">
            <b>Spam Score (%)</b>
            <template v-if="enableWidget">
                <vue-apex-charts
                    type="donut"
                    height="350"
                    :options="donutChartSpam.chartOptions"
                    :series="donutChartSpam.series"
                />
              </template>
          </b-col>
          <b-col class="mb-1" sm="6" lg="2">
            <b>Traffic (Monthly)</b>
            <h1>{{ formatNumber(this.approveData?.traffic) }}</h1>
          </b-col>
          <b-col class="mb-1" lg="2">
            <b>Traffic Value</b>
            <div><h2>USD {{ formatNumber(this.approveData?.traffic_value) }}</h2></div>
          </b-col>

          <b-col class="mb-1" lg="4">
            <b-row>
              <template v-for="(approveFor, index) in this.approveData?.approveforNiches">

              <b-col class="mb-1" cols="12">
                <h3><b-badge variant="primary">{{ approveFor?.name }}</b-badge></h3>
              </b-col>

              <b-col class="mb-1" cols="6" >
                <b>Guest Price</b>
                <h3>{{ approveData?.currency?.currency }} {{ approveFor.pivot?.guest_post_pricing }}</h3>
              </b-col>
              <b-col class="mb-1" cols="6">
                <b>Link Price</b>
                <h3>{{ approveData?.currency?.currency }} {{ approveFor.pivot?.link_insertion_pricing }}</h3>
              </b-col>

          </template>
            </b-row>
          </b-col>



        </b-row>

         <template v-if="approveData?.image.length > 0">
            <b class="mb-1">Images:</b>
            <b-row class="mb-1 mt-1">
            <b-col class="mb-1" lg="4" sm="6" v-for="(image, index) in approveData?.image" :key="index">
              <b-link :href="image.url" target="_blank" class="d-flex align-items-center">
                    <feather-icon icon="EyeIcon" class="mr-2" />
                    {{ image.caption }}
                  </b-link>
            </b-col>
          </b-row>
         </template>

         <template v-if="approveData?.href_captions.length > 0">
            <b class="mb-1">Hrefs:</b>
            <b-row class="mb-1 mt-1">
            <b-col class="mb-1" lg="4" sm="6" v-for="(href, index) in approveData?.href_captions" :key="index">
              <b-link :href="href.href" target="_blank" class="d-flex align-items-center">
                <feather-icon icon="EyeIcon" class="mr-2" />
                   {{ href.caption }}
                  </b-link>
            </b-col>
          </b-row>
         </template>

           <p>Notes: <b>{{ approveData?.remarks}}</b></p>

        <template v-if="$permissionAbility(SITE_LIST_REVIEW, permissions)">
          <b-form-group label="Reviewer Remarks" label-for="remarks">
            <b-form-textarea
                id="remarks"
                class="mb-3"
                v-model="reviewerRemarks"
                placeholder="Remarks"
                rows="3"
                :disabled="approveData?.status !== pendingStatusConstants && approveData?.status !== softRejectedStatusConstants"
            />
          </b-form-group>
          <template
             v-if="approveData?.status === pendingStatusConstants ||
              approveData?.status === softRejectedStatusConstants"
            >
              <!-- loading button -->
              <template v-if="isSubmitLoading">
                <b-button class="float-right" variant="primary" disabled>
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>
           <!-- submit button -->
              <template v-else>
                <b-button
                    type="submit"
                    class="float-right ml-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    @click="statusSubmit(rejectedStatusConstants)"
                >
                  Reject
                </b-button>
                <b-button
                    v-if="approveData?.status !== softRejectedStatusConstants"
                    type="submit"
                    class="float-right ml-2"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    @click="statusSubmit(softRejectedStatusConstants)"
                >
                  Soft Reject
                </b-button>
                <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="statusSubmit(approvedStatusConstants)"
                >
                  Approve
                </b-button>
              </template>
         </template>

        </template>

      </div>
    </b-modal>

  </b-card>
  </span>
</template>
  
  <script>
  import {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
    BFormTextarea, BFormFile, BLink, BCardText,
      BCardBody,BCardHeader

  } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";
import {
  SITE_LIST_ACCESS,
  SITE_LIST_CREATE,
  SITE_LIST_EDIT,
  SITE_LIST_DELETE,
  SITE_LIST_SHOW,
  SITE_LIST_REVIEW,
} from "@/helpers/permissionsConstant";
import { filter } from "lodash";
import { ref } from "vue";
  import UserSelect from "@/layouts/components/UserSelect.vue";
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
  import ChartjsComponentDoughnutChart
    from "@/layouts/components/dash-board/widgets/charts-components/ChartjsComponentDoughnutChart.vue";
  import {$themeColors} from "@themeConfig";
  import VueApexCharts from "vue-apexcharts";
  import {outreachStatusConstants} from "@/helpers/constant/outreachStatusConstant";
  import { approvedStatusConstants, pendingStatusConstants, rejectedStatusConstants, softRejectedStatusConstants } from "@/helpers/constant/outreachStatusConstant";

  export default {
  name: "SiteListView",
  components: {
    VueApexCharts,
    ChartjsComponentDoughnutChart,
    BCardActions, UserSelect,
    BCardText, BLink,
    BCardBody, BCardHeader,
    BFormFile,
    UserAssignPolicyForm,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      SITE_LIST_ACCESS,
      SITE_LIST_CREATE,
      SITE_LIST_EDIT,
      SITE_LIST_DELETE,
      SITE_LIST_SHOW,
      SITE_LIST_REVIEW,

      outreachStatusConstants,

      approvedStatusConstants,
      pendingStatusConstants,
      rejectedStatusConstants,
      softRejectedStatusConstants,

      donutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: ["#FFA1A1"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return ``;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "1.5rem",
                    label: "Domain",
                  },
                },
              },
            },
          },
          labels: ["Rating"],
          value: ["rating"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
      },

      donutChartSpam: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: ["#ffe700"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              return ``;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "1.5rem",
                    label: "Spam",
                  },
                },
              },
            },
          },
          labels: ["Spam"],
          value: ["spam"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
      },

      // Permissions
      modelType: "",
      name: "",
      enableWidget: false,

      siteListId: "",
      websiteUrl: "",
      selectNicheId: "",
      selectSellerId: "",
      approveData: null,
      filteredNicheOptions: [],
      filteredApprovedNicheOptions: [],
      filteredSellerOptions: [],
      trafficInput: "",
      trafficValueInput: "",
      selectedCurrency: "",
      spamScoreInput: "",
      postPriceInput: "",
      file: null,
      caption: "",
      postPriceCurrencyInput: "",
      linkInsertionPriceInput: "",
      linkInsertionPriceCurrencyInput: "",
      domainAuthorityInput: "",
      referalDomainInput: "",
      filteredProjectOptions: [],
      filteredCurrencyOptions: [],

      selectedApprovedNichePricing: [],

      filterSelectStatusId: "",
      filterSelectNicheId: "",
      filterSelectSellersId: "",

      selectProjectId: "",
      approvedForId: "",

      selectStatusId: "",

      remarksInput: "",
      reviewerRemarks: "",
      selectedCaption: "",
      selectedAttachments: null,

     
      //table
      pageLength: 10,
      columns: [
        {
          label: "Website",
          field: "website_format",
          sortable: false,
        }, {
          label: "Niche",
          field: "niche_format",
          sortable: false,
        },
        {
          label: "Seller",
          field: "seller_format",
          sortable: false,
        },
        {
          label: "Traffic",
          field: "traffic_format",
          sortable: false,
        },
        {
          label: "Traffic Value",
          field: "traffic_value_format",
          sortable: false,
        },
        {
          label: "Spam Score",
          field: "spam_score_format",
          sortable: false,
        },
        {
          label: "Status",
          field: "status_format",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },

      captionImages: [
        {
          id: null,
          required: true,
          caption: '',
          file: null,
          url: ''
        },
      ],

      captionHrefs: [
        {
          caption: '',
          href: '',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant(status) {
      const statusColor = {
        approvedStatusConstants: "light-success",
        pendingStatusConstants: "primary",
        rejectedStatusConstants: "warning",
        softRejectedStatusConstants: "danger",
      };

      return (status) => statusColor[status];
    },

    $themeColors() {
      return $themeColors
    }
  },

  async created() {
    try {
      this.loadItems();
      await this.getAllCurrencies();
      await this.getAllNiches();
      await this.getAllApprovedNiches();
      await this.getAllSellers();
      await this.getAllProjects();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {

    //image form repeater
    addCaptionImage() {
      this.captionImages.push({
        required: true,
        caption: '',
        file: null,
      });
    },
    removeCaptionImage(index) {
      this.captionImages.splice(index, 1);
    },
    handleCaptionImageChange(event ,index) {
      this.captionImages[index].file = Array.from(event.target.files);
    },

    //href form repeater
    addCaptionHref() {
      this.captionHrefs.push({
        caption: '',
        href: '',
      });
    },
    removeCaptionHref(index) {
      this.captionHrefs.splice(index, 1);
    },

    getStatusVariant(status) {

      const statusObj = outreachStatusConstants.find(
          (item) => item.value === status
      );
      return statusObj ? statusObj.variant : "light-secondary";
    },

    async statusSubmit(status){

      this.isSubmitLoading = true;

      try {
        await this.$api.put("api/site-list/status/" + this.approveData.id, {
          'status': status,
          'reviewer_remarks': this.reviewerRemarks,
        });

        let statusText = 'Approved'

        if(status === rejectedStatusConstants){
          statusText = "Rejected"
        }else if(status === softRejectedStatusConstants){
          statusText = "Soft Rejected"
        }

        this.loadItems();
        this.hiddenApproveModal()
        this.isSubmitLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Site List " + statusText + " Successfully",
          },
        });

      } catch (error) {
        this.isSubmitLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },


    renderNichePricing(selectedIds) {

      if (!selectedIds || !Array.isArray(selectedIds)) {
        return;
      }

      // Filter the full list of options to only include those with IDs in `selectedIds`
      const updatedOptions = this.filteredApprovedNicheOptions.filter((option) =>
          selectedIds.includes(option.id)
      )

      // Update `selectedApprovedNichePricing` with unique options only
      updatedOptions.forEach((option) => {
        // Check if the option already exists in `selectedApprovedNichePricing`
        const existingIndex = this.selectedApprovedNichePricing.findIndex(item => item.id === option.id);

        if (existingIndex === -1) {
          this.selectedApprovedNichePricing.push({
            id: option.id,
            name: option.name,
            guest_post_price: 0,
            link_insertion_price: 0,
          });
        }

        this.selectedApprovedNichePricing = this.selectedApprovedNichePricing.filter(item =>
            selectedIds.includes(item.id)
        );

      });
    },

    showAttachmentModal(attachment, caption) {
      this.selectedAttachments = attachment
      this.selectedCaption = caption
      this.$bvModal.show("attachment-modal");
    },

    hiddenAttachmentModal() {
      this.selectedAttachments = null
      this.selectedCaption = null
      this.$bvModal.hide("attachment-modal");
    },
    hiddenApproveModal() {
      this.enableWidget = false
      this.$bvModal.hide("approve-modal");
    },
    async deleteAttachment(attachment) {
      await this.$api.delete(`/api/outreach/site-list/${attachment?.model_id}/${attachment?.id}`);

      this.selectedAttachments = this.selectedAttachments.filter(item => item.id !== attachment.id);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Success",
          icon: "BellIcon",
          variant: "success",
          text: 'Attachment deleted successfully',
        },
      });

      this.$bvModal.hide("attachment-modal");
      this.loadItems()
    },

    formatWebsite(website) {
      const maxLength = 20;
      if (website.length > maxLength) {
        return website.slice(0, maxLength) + "...";
      }
      return website;
    },

    formatNumber(traffic) {
      if (traffic >= 1e9) {
        return (traffic / 1e9).toFixed(1) + "B";
      } else if (traffic >= 1e6) {
        return (traffic / 1e6).toFixed(1) + "M";
      } else if (traffic >= 1e3) {
        return (traffic / 1e3).toFixed(1) + "K";
      }
      return traffic;
    },

    showApproveModal() {
      this.$bvModal.show("approve-modal");
    },

    showModal() {
      this.$bvModal.show("modal-create-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-create-form");
      this.resetModal();
    },

    resetModal() {
      this.modelType = "";
      this.siteListId = "";
      this.selectedApprovedNichePricing = [];

      this.websiteUrl = "";
      this.selectNicheId = "";
      this.selectSellerId = "";
      this.selectProjectId = "";
      this.trafficInput = "";
      this.trafficValueInput = "";
      this.selectedCurrency = "";
      this.spamScoreInput = "";
      this.postPriceInput = "";
      this.postPriceCurrencyInput = "";
      this.linkInsertionPriceInput = "";
      this.linkInsertionPriceCurrencyInput = "";
      this.domainAuthorityInput = "";
      this.referalDomainInput = "";
      this.approvedForId = "";
      this.selectStatusId = "";
      this.remarksInput = "";
      this.file = null;
      this.caption = "";
      this.captionImages = [
        {
          id: null,
          required: true,
          caption: '',
          file: null,
          url: ''
        },
      ]

      this.captionHrefs = [
        {
          caption: '',
          href: '',
        },
      ]
    },

    onApprove(value) {
      this.approveData = value;

      this.reviewerRemarks = value.reviewer_remarks

      this.donutChart.series = [value?.domain_authority];
      this.donutChartSpam.series = [value?.spam_score];

      setTimeout(() => {
        this.enableWidget = true;
      }, 1000); // 20000 milliseconds = 20 seconds


      this.showApproveModal()
    },

    onShow(value) {
      this.modelType = "editModel";
      this.siteListId = value.id;

      this.websiteUrl = value.website;
      this.selectNicheId = value.nich_id;
      this.selectSellerId = value.seller_id;
      this.trafficInput = value.traffic;
      this.trafficValueInput = value.traffic_value;
      this.selectedCurrency = value.currency_id;
      this.spamScoreInput = value.spam_score;
      this.domainAuthorityInput = value.domain_authority;
      this.referalDomainInput = value.referal_domain;
      this.approvedForId = [];
      this.captionImages = [];
      this.captionHrefs = [];
      this.remarksInput = value.remarks;
      this.captionHrefs = value.captionHrefs;

      this.captionImages = Array.isArray(value.image)
          ? value.image.map(item => ({
            required: false,
            id: item.id,
            caption: item.caption || '',
            file: null,
            url: item.url || '',
          }))
          : [];

      this.captionHrefs = Array.isArray(value.href_captions)
          ? value.href_captions.map(item => ({
            caption: item.caption || '', // Default to an empty string if caption is undefined
            href: item.href || '',       // Default to an empty string if href is undefined
          }))
          : [];


      const approveForNiche = value.approveforNiches

      if(approveForNiche){
        approveForNiche.forEach(option => {

          this.approvedForId.push(option.id);

          this.selectedApprovedNichePricing.push({
            id: option.id,
            name: option.name,
            guest_post_price: option.pivot.guest_post_pricing,
            link_insertion_price: option.pivot.link_insertion_pricing,
          });
        });
      }

      this.showModal();
    },

    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },

    async onDelete(row) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete "+row?.website+"?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/site-lists/${row.id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Site List Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      this.isLoading = true
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    async getSiteListItems(params) {
      return await this.$api.get("api/site-lists?include=seller,niche", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
          filterSelectStatusId: this.filterSelectStatusId,
          filterSelectNicheId: this.filterSelectNicheId,
          filterSelectSellersId: this.filterSelectSellersId,
        },
      });
    },

    async getAllCurrencies() {
      try {
        const response = await this.$api.get("api/currencies/all");

        this.filteredCurrencyOptions = (response?.data?.data || []).map((item) => {
          let name = item.currency;
          return {
            name,
            id: item.id,
          };
        });
        
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllNiches() {
      try {
        const response = await this.$api.get("api/outreach/niche/all");

        this.filteredNicheOptions = (response?.data?.data || []).map((item) => {
          let name = item.name;
          return {
            name,
            id: item.id,
          };
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllApprovedNiches() {
      try {
        const response = await this.$api.get("api/outreach/approverfor/niche/all");

        this.filteredApprovedNicheOptions = (response?.data?.data || []).map((item) => {
          let name = item.name;
          return {
            name,
            id: item.id,
          };
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllSellers() {
      try {
        const response = await this.$api.get("api/outreach/seller/all");

        this.filteredSellerOptions = (response?.data?.data || []).map((item) => {
          let name = item.name;
          return {
            name,
            id: item.id,
          };
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getAllProjects() {
      try {
        const response = await this.$api.get("api/projects/approve");

        this.filteredProjectOptions = (response?.data?.data || []).map((item) => {
          let name = item.lead.title;
          return {
            name,
            id: item.id,
          };
        });

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const siteListData = await this.getSiteListItems({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = siteListData?.data?.data;
        const meta = siteListData?.data?.meta;

        this.totalRecords = meta?.pagination?.total;

        this.rows = data;

        this.isLoading = false;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {

      this.$refs.createFormValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.createFormValidation.reset();

            if (this.modelType == "editModel") {
              this.isSubmitLoading = true;

              const formData = new FormData();

              this.captionImages.forEach((item, index) => {

                if(item.id){
                  formData.append(`image_id[${index}]`, item.id);
                }

                formData.append(`image_captions[${index}]`, item.caption);
                if (item.file) {
                  formData.append(`images[${index}]`, item.file);
                }
              });

              formData.append('href_captions', JSON.stringify(this.captionHrefs)); // Add the JSON array to the form data

              if (this.websiteUrl) {
                formData.append("website", this.websiteUrl);
              }

              formData.append("_method", "PUT");

              if (this.selectNicheId) {
                formData.append("nich_id", this.selectNicheId);
              }

              if (this.selectSellerId) {
                formData.append("seller_id", this.selectSellerId);
              }

              if (this.trafficInput) {
                formData.append("traffic", this.trafficInput);
              }

              if (this.trafficValueInput) {
                formData.append("traffic_value", this.trafficValueInput);
              }

              if (this.selectedCurrency) {
                formData.append("currency_id", this.selectedCurrency);
              }

              if (this.spamScoreInput) {
                formData.append("spam_score", this.spamScoreInput);
              }

              if (this.domainAuthorityInput) {
                formData.append("domain_authority", this.domainAuthorityInput);
              }

              if (this.referalDomainInput) {
                formData.append("referal_domain", this.referalDomainInput);
              }

              if (this.approvedForId) {
                formData.append("approved_for", this.approvedForId);
              }

              if (this.selectStatusId) {
                formData.append("status", this.selectStatusId);
              }

              if (this.remarksInput) {
                formData.append("remarks", this.remarksInput);
              }

              if(this.selectedApprovedNichePricing){
                formData.append("niche_approved_pricing", JSON.stringify(this.selectedApprovedNichePricing));
              }

              await this.$api.post(`/api/site-lists/${this.siteListId}`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data", // Ensures the server treats the request correctly
                },
              });

              this.isSubmitLoading = false;
              this.loadItems();

              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Site List Successfully Updated",
                },
              });
            } else {
              this.isSubmitLoading = true;

              const formData = new FormData();

              if (this.websiteUrl) {
                formData.append("website", this.websiteUrl);
              }

              formData.append("nich_id", this.selectNicheId);
              formData.append("seller_id", this.selectSellerId);
              formData.append("traffic", this.trafficInput);
              formData.append("traffic_value", this.trafficValueInput);
              formData.append("currency_id", this.selectedCurrency);
              formData.append("spam_score", this.spamScoreInput);
              formData.append("post_price", this.postPriceInput);
              formData.append("post_price_currency", this.postPriceCurrencyInput);
              formData.append("link_insertion_price", this.linkInsertionPriceInput);
              formData.append("link_insertion_price_currency", this.linkInsertionPriceCurrencyInput);
              formData.append("domain_authority", this.domainAuthorityInput);
              formData.append("referal_domain", this.referalDomainInput);
              formData.append("approved_for", this.approvedForId);
              formData.append("remarks", this.remarksInput);

              formData.append("niche_approved_pricing", JSON.stringify(this.selectedApprovedNichePricing));

              this.captionImages.forEach((item, index) => {
                formData.append(`image_captions[${index}]`, item.caption); // Use a unique name for image captions
                if (item.file) {
                  formData.append(`images[${index}]`, item.file); // Append image file
                }
              });

              formData.append('href_captions', JSON.stringify(this.captionHrefs)); // Add the JSON array to the form data

              await this.$api.post("/api/site-lists", formData, {
                headers: {
                  "Content-Type": "multipart/form-data", // Required for FormData submissions
                },
              });

              this.isSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Site List Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.createFormValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>
  
  